<template>
  <form-component
    class="comment-form"
    data-test-id="comment-form"
    @submit.prevent="addComment"
  >
    <input type="hidden" :value="parentCommentId">
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.body.$invalid }"
    >
      <validation-error
        ref="body"
        :model="body"
        :validator="$v.body"
      />
      <mention-box
        ref="mentionBox"
        :mentionable="mentionable"
        :placeholder="placeholderText"
        mentionable-type="POST"
        @input="handleCommentBody"
      />
    </div>
    <loading-button
      class="button--space-top button--center"
      type="submit"
      :loading="tryingToComment"
      button-type="comment"
    >
      {{ buttonText }}
    </loading-button>
  </form-component>
</template>

<script>
import MentionBox from '@/components/Mention/MentionBox.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';
import { ADD_COMMENT } from '@/graphql/mutations/comment-mutation';
import { COMMENT_UPDATE_QUERY } from '@/graphql/queries/comment-queries';
import { getTypesForPostQuery } from '@/utils/post-helpers';
import { required } from 'vuelidate/lib/validators';
import ValidationError from '@/components/Global/ValidationError.vue';
import { mapGetters } from 'vuex';
import { notJustMention } from '@/utils/utils';

export default {
  name: 'CommentForm',
  components: {
    MentionBox,
    LoadingButton,
    ValidationError,
  },
  props: {
    mentionable: {
      type: Object,
      required: true,
    },
    parentCommentId: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Post comment',
    },
    placeholderText: {
      type: String,
      default: 'Write a comment...',
    },
  },
  data() {
    return {
      body: '',
      tryingToComment: false,
      submissionAttempted: false,
    };
  },
  validations: {
    body: {
      required,
      notJustMention,
    },
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userIsUnverified',
    ]),
  },
  watch: {
    body(to, from) {
      if (to !== from && this.submissionAttempted) {
        this.$refs.body.checkErrors();
      }
    },
  },
  methods: {
    triggerMention(userForMention) {
      this.$refs.mentionBox.injectMention(userForMention);
    },
    addComment() {
      if (this.userIsLoggedIn && this.userIsUnverified) {
        this.$store.dispatch('openVerificationPrompt', { dialogHeading: 'Verify your account' });
        return;
      }
      this.tryingToComment = true;
      this.$v.$touch();
      this.$refs.body.checkErrors();
      this.submissionAttempted = true;
      if (this.$v.$invalid) {
        this.tryingToComment = false;
        return;
      }
      this.$apollo.mutate({
        mutation: ADD_COMMENT,
        variables: {
          body: this.body,
          parentCommentId: this.parentCommentId || null,
          postId: this.mentionable.id,
        },
        update: (cache, { data: { createComment } }) => {
          // do not update comments that are pending moderation
          if (createComment.isPendingModeration) {
            this.$store.dispatch('addToastNotification', {
              toastType: 'info',
              description: 'Your comment will be displayed once our moderators have reviewed it\'s content.',
              type: 'Comments',
            });
            return;
          }
          // read from cache
          const data = cache.readQuery({
            query: COMMENT_UPDATE_QUERY,
            variables: {
              id: this.$route.params.id || null,
              slug: this.$route.params.slug || null,
              category: this.$route.params.category || null,
              isPreview: this.$route.query.preview === 'true',
              types: getTypesForPostQuery(this.mentionable.type),
            },
          });

          // eslint-disable-next-line no-param-reassign
          createComment.comments = [];

          // update cache data
          if (createComment.parentCommentId) { // Comment is a reply
            const topLevelIds = data.post.comments.map((c) => c.id);
            const parentCommentIndex = topLevelIds.indexOf(createComment.parentCommentId);
            data.post.comments[parentCommentIndex].comments.push(createComment);
          } else { // Top level comment
            const sortType = this.$site.settings.comment_and_reply_sort;
            if (!sortType || sortType === 'desc') {
              data.post.comments.unshift(createComment);
            } else {
              data.post.comments.push(createComment);
            }
          }

          // write to cache
          cache.writeQuery({
            query: COMMENT_UPDATE_QUERY,
            data,
          });

          this.$store.dispatch('addToastNotification', {
            toastType: 'success',
            description: 'Your comment was posted!',
            type: 'Comments',
          });
        },
      })
        .then(({ data: { createComment } }) => {
          this.body = '';
          this.$refs.mentionBox.clear();
          this.$v.$reset();
          this.$nextTick(() => {
            if (this.$refs.body) {
              this.$refs.body.resetErrors();
            }
          });
          if (createComment.isPendingModeration) {
            return true;
          }
          this.$emit('submitted', createComment.id);
          return true;
        })
        .catch(() => {
          this.$store.dispatch('addGenericErrorNotification');
        }).finally(() => {
          this.tryingToComment = false;
        });
    },
    handleCommentBody(text) {
      this.body = text;
    },
  },
};
</script>

<docs>
A form for comments and replies

</docs>
