import gql from 'graphql-tag';

export const ADD_COMMENT = gql`
  mutation createComment($body: Ugc!, $parentCommentId: NexusId, $postId: NexusId!) {
    createComment(body: $body, parentCommentId: $parentCommentId, postId: $postId) {
      isPendingModeration
      body
      insertedAt
      id
      user{
        id
        username
        insertedAt
        badge
        roleId
        avatar {
          thumb
        }
      }
      parentCommentId
    }
  }
`;

export const DELETE_COMMENT = gql`
mutation deleteComment($id: NexusId!) {
  deleteComment(id: $id) {
    id
  }
}
`;
