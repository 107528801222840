<template>
  <div
    v-if="!destroyed"
    class="inline-image"
    :data-src="src"
    :data-alt="alt"
    contenteditable="false"
  >
    <div class="inline-image__wrapper" contenteditable="false">
      <button
        class="inline-image__delete-button link link--unstyled"
        type="button"
        @click.prevent="destroy"
      >
        <icon-component
          name="trash-can"
          title="Delete image"
          class="inline-image__delete-button-icon"
        />
      </button>
      <img
        class="inline-image__img"
        data-test-id="inline-image-img"
        :src="src"
        :alt="alt"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'InlineImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      destroyed: false,
    };
  },
  methods: {
    destroy() {
      this.destroyed = true;
      this.$emit('delete', this.src);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_inline-image';
</style>

<docs>
</docs>
