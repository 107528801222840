<template>
  <button
    class="image-upload-button button button--secondary"
    type="button"
    data-test-id="image-upload-button"
    @click="handleClick"
  >
    <icon-component name="photo" class="image-upload-button__icon button__icon" />
    <slot>Upload image</slot>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ImageUploadButton',
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userIsUnverified',
    ]),
  },
  methods: {
    handleClick(event) {
      if (this.userIsLoggedIn && this.userIsUnverified) {
        this.$store.dispatch('openVerificationPrompt', { dialogHeading: 'Verify your account' });
        return;
      }
      if (!this.userIsLoggedIn) {
        this.$store.dispatch('openRegisterDialog', { dialogText: 'Create an account to upload an image.', isUGC: true });
        return;
      }
      this.$snowplow.trackButtonEvent({
        data: {
          type: 'upload image',
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_image-upload-button';
</style>

<docs>
A stylized button component that can be used to trigger the ImageUploadDialog
</docs>
