<template>
  <li class="context-menu-option">
    <button
      class="context-menu-option__button"
      @click="handleClick"
    >
      <span
        class="context-menu-option__button-text"
      >
        <slot />
      </span>
      <icon-component
        v-if="icon"
        :name="icon"
        class="context-menu-option__button-icon icon--small"
      />
    </button>
  </li>
</template>

<script>
export default {
  name: 'ContextMenuOption',
  props: {
    icon: {
      type: String,
      default: '',
    },
    /**
     * deleteType is being
     * used for snowplow button
     * tracking below
     */
    deleteType: {
      type: String,
      default: '',
    },
  },
  computed: {
    buttonType() {
      if (this.$slots.default[0].text === 'Report') {
        return 'report';
      } if (this.$slots.default[0].text === 'Delete' && this.deleteType === 'status') {
        return 'delete status';
      }
      return 'context-menu';
    },
  },
  methods: {
    handleClick(event) {
      this.$snowplow.trackButtonEvent({
        data: {
          type: this.buttonType,
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_context-menu-option';
</style>

<docs>
ContextMenuOption is an option that the ContextMenu component calls.
Optionally accepts an icon prop which should match an icon svg name.
Emits a click event on click, to be handled by the parent.

</docs>
