<template>
  <form-component
    class="thread-form"
    data-test-id="thread-form"
    :class="{ 'thread-form--parent' : !parentReplyId }"
    @submit.prevent="addReply"
  >
    <input type="hidden" :value="parentReplyId">
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.body.$invalid }"
    >
      <validation-error
        ref="body"
        :model="body"
        :validator="$v.body"
      />
      <mention-box
        ref="mentionBox"
        :mentionable="mentionable"
        mentionable-type="THREAD"
        placeholder="Write a reply..."
        @input="handleReplyBody"
      />
    </div>
    <div class="thread-form__prompt disclaimer-text">
      Please
      <a href="https://health-union.com/community-rules/"
         target="_blank"
         rel="noopener noreferrer nofollow"
      >
        read our rules</a>
      before posting.
    </div>
    <loading-button
      class="thread-form__button"
      type="submit"
      :loading="tryingToReply"
      button-type="reply"
    >
      Post reply
    </loading-button>
  </form-component>
</template>

<script>
import MentionBox from '@/components/Mention/MentionBox.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';
import { CREATE_REPLY } from '@/graphql/mutations/thread-mutation';
import { REPLY_UPDATE_QUERY } from '@/graphql/queries/thread-reply-queries';
import { required } from 'vuelidate/lib/validators';
import ValidationError from '@/components/Global/ValidationError.vue';
import { notJustMention } from '@/utils/utils';
import { mapGetters } from 'vuex';

export default {
  name: 'ThreadForm',
  components: {
    MentionBox,
    LoadingButton,
    ValidationError,
  },
  props: {
    mentionable: {
      type: Object,
      required: true,
    },
    parentReplyId: {
      type: String,
      default: null,
    },
    parentThreadId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      body: '',
      tryingToReply: false,
      submissionAttempted: false,
    };
  },
  validations: {
    body: {
      required,
      notJustMention,
    },
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userIsUnverified',
    ]),
  },
  watch: {
    body(to, from) {
      if (to !== from && this.submissionAttempted) {
        this.$refs.body.checkErrors();
      }
    },
  },
  methods: {
    triggerMention(userForMention) {
      this.$refs.mentionBox.injectMention(userForMention);
    },
    handleReplyBody(text) {
      this.body = text;
    },
    addReply() {
      if (this.userIsLoggedIn && this.userIsUnverified) {
        this.$store.dispatch('openVerificationPrompt', { dialogHeading: 'Verify your account' });
        return;
      }
      this.tryingToReply = true;
      this.$v.$touch();
      this.$refs.body.checkErrors();
      this.submissionAttempted = true;

      if (this.$v.$invalid) {
        this.tryingToReply = false;
        return;
      }

      this.$apollo.mutate({
        mutation: CREATE_REPLY,
        variables: {
          body: this.body,
          inReplyToId: this.parentReplyId,
          threadId: this.parentThreadId,
        },
        update: (cache, { data: { createReply } }) => {
          // do not update replies that are pending moderation
          if (createReply.isPendingModeration) {
            this.$store.dispatch('addToastNotification', {
              toastType: 'info',
              description: 'Your reply will be displayed once our moderators have reviewed it\'s content.',
              type: 'Forum',
            });
            return;
          }

          // read from cache
          const data = cache.readQuery({
            query: REPLY_UPDATE_QUERY,
            variables: {
              id: null,
              slug: this.$route.params.slug || null,
            },
          });

          // update cache data
          if (createReply.inReplyTo) { // Reply is a child reply
            const topLevelIds = data.thread.replies.map((r) => r.id);
            const inReplyToIndex = topLevelIds.indexOf(createReply.inReplyTo.id);
            data.thread.replies[inReplyToIndex].replies.push(createReply);
          } else { // Top level reply
            const sortType = this.$site.settings.comment_and_reply_sort;
            if (!sortType || sortType === 'desc') {
              data.thread.replies.unshift(createReply);
            } else {
              data.thread.replies.push(createReply);
            }
            if (data.thread.replies.length === 1) {
              this.$emit('firstReply');
            }
          }

          // write to cache
          cache.writeQuery({
            query: REPLY_UPDATE_QUERY,
            data,
          });
          this.$store.dispatch('addToastNotification', {
            toastType: 'success',
            description: 'Your reply was posted!',
            type: 'Forum',
          });
        },
      })
        .then(({ data: { createReply } }) => {
          this.body = '';
          if (this.$refs.mentionBox) {
            this.$refs.mentionBox.clear();
          }
          this.$v.$reset();
          if (!createReply.inReplyTo) {
            this.$nextTick(() => {
              if (this.$refs.body) {
                this.$refs.body.resetErrors();
              }
            });
          }
          if (createReply.isPendingModeration) {
            return true;
          }
          this.$emit('submitted', createReply);
          return true;
        })
        .catch(() => {
          this.$store.dispatch('addGenericErrorNotification');
        }).finally(() => {
          this.tryingToReply = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_thread-form';
</style>
