<template>
  <ul class="uploaded-image-preview">
    <inline-image
      v-for="image in images"
      :key="`upload-image-preview-${image.src}`"
      :src="image.src"
      :alt="image.alt"
      @delete="handleDelete"
    />
  </ul>
</template>

<script>
import InlineImage from '@/components/ImageUpload/InlineImage.vue';

export default {
  name: 'UploadedImagePreview',
  components: {
    InlineImage,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imagesCopy: this.images,
    };
  },
  methods: {
    handleDelete(imgSrc) {
      const indexToDelete = this.imagesCopy.findIndex((img) => img.src === imgSrc);
      this.images.splice(indexToDelete, 1);
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_uploaded-image-preview';
</style>

<docs>
</docs>
