<template>
  <dialog-base
    class="confirmation-dialog"
    @close="close"
  >
    <template
      v-slot:heading
    >
      {{ heading }}
    </template>
    <div class="confirmation-dialog__actions">
      <button-component
        class="button--secondary"
        @click="close"
      > Cancel
      </button-component>
      <button-component
        @click="confirm"
      >
        {{ confirmText }}
      </button-component>
    </div>
  </dialog-base>
</template>

<script>
import DialogBase from '@/components/Global/DialogBase.vue';
import ButtonComponent from '@/components/Global/ButtonComponent.vue';

export default {
  name: 'ConfirmationDialog',
  components: {
    DialogBase,
    ButtonComponent,
  },
  props: {
    heading: {
      type: String,
      default: 'Are you sure?',
    },
    confirmText: {
      type: String,
      default: 'Yes',
    },
  },
  methods: {
    close() {
      this.$store.dispatch('closeConfirmationDialog');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_confirmation-dialog';
</style>
