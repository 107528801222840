<template>
  <div class="engagement-bar engagement-bar--reply">
    <div class="engagement-bar__actions" data-test-id="engagement-reply-bar-actions">
      <reaction-menu
        :id="comment.id"
        :content-type="objectType"
      />
      <button
        v-if="!theObject.isClosed"
        class="engagement-bar__button"
        data-test-id="engagement-reply-bar-reply-button"
        aria-label="Reply"
        :aria-expanded="showForm && !isParentThread ? 'true' : 'false'"
        @click="toggleReplyForm"
      >
        <icon-component name="reply-arrow" class="engagement-bar__action-icon" />
        <span class="engagement-bar__action-text">reply</span>
      </button>
      <context-menu :is-by-current-user="isByCurrentUser">
        <template v-slot:admin>
          <context-menu-option
            icon="trash-can"
            @click="moderatorDelete"
          >Delete</context-menu-option>
          <context-menu-option
            icon="pencil"
            @click="openModerationContentFlag"
          >Edit</context-menu-option>
        </template>
        <template v-slot:moderator>
          <context-menu-option
            icon="flag"
            @click="markAsSpam"
          >Mark as spam</context-menu-option>
        </template>
        <template v-slot:currentUser>
          <context-menu-option icon="trash-can" @click="deleteContent">Delete</context-menu-option>
        </template>
        <template>
          <context-menu-option icon="flag" @click="reportContentOrUser">Report</context-menu-option>
        </template>
      </context-menu>
    </div>
    <comment-form
      v-if="showCommentForm"
      ref="replyForm"
      :mentionable="theObject"
      :parent-comment-id="comment.parentCommentId || comment.id"
      button-text="Post reply"
      placeholder-text="Write a reply..."
      @submitted="handleSubmitted"
    />
    <thread-form
      v-if="showThreadForm"
      ref="threadForm"
      :mentionable="theObject"
      :parent-reply-id="parentReplyToId"
      :parent-thread-id="theObject.id"
      @submitted="handleSubmitted"
    />
  </div>
</template>

<script>
import ContextMenu from '@/components/Engagement/ContextMenu.vue';
import ContextMenuOption from '@/components/Engagement/ContextMenuOption.vue';
import ReactionMenu from '@/components/Reactions/ReactionMenu.vue';
import CommentForm from '@/components/Comments/CommentForm.vue';
import ThreadForm from '@/components/Thread/ThreadForm.vue';
import moderationActionsMixin from '@/mixins/moderation-actions-mixin';
import { typeToAssocType } from '@/utils/moderation-helpers';
import scrollToMixin from '@/mixins/scroll-to-mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'EngagementReplyBar',
  components: {
    ContextMenu,
    ContextMenuOption,
    ReactionMenu,
    CommentForm,
    ThreadForm,
  },
  mixins: [
    moderationActionsMixin,
    scrollToMixin,
  ],
  props: {
    objectType: {
      type: String,
      default: 'comment',
    },
    comment: {
      type: Object,
      required: true,
    },
    theObject: {
      type: Object,
      required: true,
    },
    isParentThread: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showForm: false,
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'currentUser',
    ]),
    moderation() {
      return {
        assocId: this.comment ? this.comment.id : this.theObject.id,
        assocType: typeToAssocType(this.objectType),
      };
    },
    isForumType() {
      return ['thread', 'reply'].includes(this.objectType);
    },
    showCommentForm() {
      return !this.isForumType && this.showForm;
    },
    showThreadForm() {
      return this.isForumType && this.showForm;
    },
    form() {
      return this.isForumType ? this.$refs.threadForm : this.$refs.replyForm;
    },
    userForMention() {
      return {
        id: this.comment.user.id,
        username: this.comment.user.username,
      };
    },
    userForMentionIsValid() {
      return !!(this.userForMention.id && this.userForMention.username);
    },
    parentReplyToId() {
      if (this.comment.inReplyTo) {
        return this.comment.inReplyTo.id;
      }
      if (this.isParentThread) {
        return null;
      }
      return this.comment.id;
    },
    isByCurrentUser() {
      return this.comment.user && this.comment.user.id === this.currentUser.id;
    },
    confirmationDialogHeading() {
      return 'Are you sure you want to delete this thread?';
    },
  },
  methods: {
    toggleReplyForm(event) {
      if (!this.userIsLoggedIn && !this.isForumType) {
        this.$store.dispatch('openRegisterDialog', { dialogText: 'Create an account to comment.' });
        return;
      }
      if (!this.userIsLoggedIn) {
        this.$store.dispatch('openRegisterDialog', { isUGC: true });
        return;
      }
      this.$snowplow.trackButtonEvent({
        data: {
          type: 'reply',
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      if (!this.isParentThread) {
        this.showForm = !this.showForm;
        if (this.showForm && this.userForMentionIsValid) {
          this.$nextTick(() => {
            if (this.objectType === 'comment') {
              this.$refs.replyForm.triggerMention(this.userForMention);
            } else {
              this.$refs.threadForm.triggerMention(this.userForMention);
            }
          });
        }
      }
      if (this.isParentThread) {
        const formElement = document.querySelector('.thread-form--parent');
        if (formElement) {
          this.scrollToForm(formElement);
          this.$emit('openForm');
        }
      }
    },
    scrollToForm(element) {
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const formHeight = element.offsetHeight;
      const scrollTop = element.offsetTop - (windowHeight / 2) + (formHeight);
      window.scrollTo({ top: scrollTop, behavior: 'instant' });
    },
    openMentionbox() {
      this.form.$refs.mentionBox.openMentionbox();
    },
    maybeInjectMentionToForm() {
      if (this.userForMentionIsValid) {
        this.$nextTick(() => {
          this.form.$refs.mentionBox.injectMention(this.userForMention);
        });
      }
    },
    handleSubmitted(replyData) {
      this.showForm = false;
      this.$emit('submitted', replyData);
    },
    deleteContent() {
      if (!this.isByCurrentUser) {
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: 'Not authorized',
        });
      }
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_engagement-bar';
</style>

<docs>
</docs>
