// This plugin adds image upload functionality to a form
// Uploaded images will be injected into a contenteditable element on the page

// To use this plugin you must:
// * Include the contenteditable-helpers mixin
// * Add ref="contenteditable" to the target element

// By default this plugin will inject imageUploads to a data attr called `body`
// to change the data attr used for imageUploadable input you should define
// a computed called imageDataAttr which uses getter/setter syntax as below
// * Getter returns the data attr mapped to the contentEditable
// * Setter sets the value of the same data attr
import { generateId } from '@/utils/utils';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    imageUploadId: generateId(),
    images: [],
  }),
  computed: {
    ...mapGetters([
      'uploadedImages',
    ]),
    hasImage() {
      return this.images.length > 0;
    },
  },
  watch: {
    uploadedImages(to) {
      if (to.length > 0 && to[0].mentionBoxId === this.imageUploadId) {
        to.forEach((img) => {
          this.addImage(img);
        });
        this.$nextTick(() => {
          this.$store.dispatch('clearUploadedImages');
        });
      }
    },
  },
  methods: {
    clearImages() {
      this.images = [];
    },
    // This event handler should be attached to the @click event of the image upload
    // button in the implementing component
    handleImageUploadClick() {
      if (this.hasImage) {
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: 'You may only upload one image.',
        });
        return;
      }
      this.$store.dispatch('openImageUploadDialog', this.imageUploadId);
    },
    // This method converts the InlineImage component to an <img> element
    // It should be called whenever the imageDataAttr changes
    imagesToHtml() {
      return this.images.map((img) => `<img src="${img.src}" alt="${img.alt}" class="uploaded-image">`).join('');
    },
    appendImages(content) {
      if (this.images.length <= 0) { return content; }
      return `${content}${this.imagesToHtml()}`;
    },
    // Proceedure for inserting an image into the contenteditable
    // Called by the watcher on uploadedImages
    addImage(uploadedImage) {
      if (!uploadedImage.url) return;

      const propsData = {
        src: `/content/${uploadedImage.url}`,
        alt: uploadedImage.alt,
      };
      this.images.push(propsData);
      if (this.$refs.contentEditableInput) {
        this.$refs.contentEditableInput.focus();
      }
      if (typeof this.updateContent === 'function') {
        this.updateContent();
      }
    },
  },
};
