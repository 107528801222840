<template>
  <div
    class="context-menu"
    :class="{ 'context-menu--open': contextMenuIsOpen }"
  >
    <icon-component
      container="button"
      name="ellipsis"
      title="more actions"
      :aria-expanded="contextMenuIsOpen ? 'true' : 'false'"
      aria-controls="context-menu-options"
      class="context-menu__button"
      @click="toggleMenu"
    />
    <ul
      v-if="contextMenuIsOpen"
      id="context-menu-options"
      ref="menu"
      v-click-outside="onClickOutside"
      class="context-menu__options"
      :class="{ 'context-menu__options--up': menuOpensAboveButton }"
    >
      <slot v-if="isAdmin" name="admin" />
      <slot v-if="isModeratorOrAdmin" name="moderator" />
      <slot v-if="isByCurrentUser && !isAdmin" name="currentUser" />
      <slot v-if="!isByCurrentUser && !isModeratorOrAdmin" />
      <slot name="allUsers" />
    </ul>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { mapGetters } from 'vuex';

export default {
  name: 'ContextMenu',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    isByCurrentUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOpensAboveButton: false,
      contextMenuIsOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'isModeratorOrAdmin',
      'isAdmin',
    ]),
  },
  methods: {
    getMenuHeightToViewport() {
      const menuSize = this.$refs.menu.getBoundingClientRect();
      if (menuSize.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
        this.menuOpensAboveButton = false;
        return;
      }
      this.menuOpensAboveButton = true;
    },
    toggleMenu() {
      this.contextMenuIsOpen = !this.contextMenuIsOpen;
      if (this.contextMenuIsOpen) {
        this.$nextTick(() => this.getMenuHeightToViewport());
      }
      if (!this.contextMenuIsOpen) {
        this.menuOpensAboveButton = false;
      }
    },
    onClickOutside() {
      if (!this.contextMenuIsOpen) { return; }
      this.toggleMenu();
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_context-menu';
</style>

<docs>
ContextMenu displays an ellipsis inside the engagement toolbar
and calls the options based on the context of the content and user.

The context menu options can be overridden by passing a v-slot directive
with the currentUser's role.

</docs>
