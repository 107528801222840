import scrollToMixin from '@/mixins/scroll-to-mixin';

export default {
  mixins: [scrollToMixin],
  methods: {
    scrollToRouteHash() {
      this.scrollTo(this.$route.hash);
    },
  },
};
